$(document).on('turbolinks:load', function() {
  set_datepicker();
  set_rangepicker();
  set_datepicker2();
  set_rangepicker2();
  set_selected_orders();
})

function set_rangepicker()
{
  $('.range-picker').daterangepicker({
    format: 'YYYY-MM-DD',
    autoUpdateInput: false,
    ranges: {
      'Šiandien': [moment(), moment()],
      'Vakar': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Paskutinės 7d.': [moment().subtract(6, 'days'), moment()],
      'Paskutinės 30d.': [moment().subtract(29, 'days'), moment()],
      'Šį mėnesį': [moment().startOf('month'), moment().endOf('month')],
      'Praėjusį mėnesį': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
     
    },
    locale: {
      applyLabel: 'Patvirtinti',
      cancelLabel: 'Valyti',
      fromLabel: 'Nuo',
      toLabel: 'Iki',
      customRangeLabel: 'Custom',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr','Sa'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1,
      customRangeLabel: "Pasirinktinis",
      format: 'YYYY-MM-DD'
    },
    
  });

  $('#date').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));
    
    $('#q_created_at_gteq').val(picker.startDate.format('YYYY-MM-DD'));
    $('#q_created_at_lteq').val(picker.endDate.format('YYYY-MM-DD'));
  
  });
  
  $('#date').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
    
    $('#q_created_at_gteq').val('');
    $('#q_created_at_lteq').val('');
  });

}

function set_selected_orders() {
  $('#change_selected_orders').on('click', function(e) {
    e.preventDefault();
    var selected_orders = [];
    $('.select_order:checked').each(function() {
      selected_orders.push($(this).val());
    });

    if(selected_orders.length < 1) {
      alert('Pasirinkite užsakymus');
      return;
    }
  
    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: '/orders/change_selected_orders',
      data: {selected_orders: selected_orders}
    });
  })
}


function set_datepicker()
{
  $('.date').datepicker({
    language: 'lt',
    autoclose: true,
    format: 'yyyy-mm-dd'
  });
}

function set_rangepicker2()
{
  $('#date2').daterangepicker({
    format: 'YYYY-MM-DD',
    autoUpdateInput: false,
    ranges: {
      'Šiandien': [moment(), moment()],
      'Vakar': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Paskutinės 7d.': [moment().subtract(6, 'days'), moment()],
      'Paskutinės 30d.': [moment().subtract(29, 'days'), moment()],
      'Šį mėnesį': [moment().startOf('month'), moment().endOf('month')],
      'Praėjusį mėnesį': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
     
    },
    locale: {
      applyLabel: 'Patvirtinti',
      cancelLabel: 'Valyti',

      fromLabel: 'Nuo',
      toLabel: 'Iki',
      customRangeLabel: 'Custom',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr','Sa'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1,
      customRangeLabel: "Pasirinktinis",
      format: 'YYYY-MM-DD'
    }
  })

  $('#date2').on('apply.daterangepicker', function(ev, picker) {
    console.log(picker.endDate.format('YYYY-MM-DD'))
    $(this).val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));
    
    $('#q_finished_at_gteq').val(picker.startDate.format('YYYY-MM-DD'));
    $('#q_finished_at_lteq').val(picker.endDate.format('YYYY-MM-DD'));
  
  });
  
  $('#date2').on('cancel.daterangepicker', function(ev, picker) {
    console.log('cliear')
    $(this).val('');
    
    $('#q_finished_at_gteq').val('');
    $('#q_finished_at_lteq').val('');
  });
}



function set_datepicker2()
{
  $('.date2').datepicker({
    language: 'lt',
    autoclose: true,
    format: 'yyyy-mm-dd'
  });
}
