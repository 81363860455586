// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import jquery from 'jquery';
window.$ = window.jquery = jquery;

import 'bootstrap'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import moment from 'moment'
window.moment = moment

import 'bootstrap-daterangepicker'
import 'bootstrap-datepicker'
import './custom'